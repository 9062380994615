import React, { useMemo } from 'react';
import { getMarkerEnd, useStore, useNodes, useEdges } from 'reactflow';

import {
  ClientSideNodeTypes
} from './pedigree-constants';
import { createHook, createLine, getEdgeParams, createUUID } from './utils';

const FloatingStraightEdge= (props) => {
  const id = props.id;
  const source = props.source;
  const target = props.target;
  const arrowHeadType = props.arrowHeadType;
  const markerEndId = props.markerEndId;
  const style = props.style;

  const nodes = useNodes()
  const edges = useEdges()
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const sourceNode = useMemo(() => nodes.find((n) => n.id === source), [source, nodes]);
  const targetNode = useMemo(() => nodes.find((n) => n.id === target), [target, nodes]);

  // Note: we may need to switch to this function if it is faster to memoize the anonymous function
  // useMemo must be used before a return in a non conditional way
  // const partnerEdge = useMemo(() => edges.find((edge) => targetNode && sourceNode && edge.target === targetNode.id && edge.source !== sourceNode.id), [sourceNode, targetNode, edges]);

  if (!sourceNode || !targetNode) {
    return null;
  }

  // if the source node is blood related to proband (so the relationship isnt counted twice)
  // and the target node is also the target node of a partner of the soruce node then check for separated,
  // divorced and Consanguineous
  // we are assuming this edge type is being used for partners (parents)
  // we are searching for another edge that use this target as a target and its not the current source node
  let draw_separated_relationship = false;
  let draw_divorced_relationship = false;
  let draw_consanguineous_relationship = false;

  if (sourceNode.type === ClientSideNodeTypes.PERSON) {
    const partnerEdge = edges.find((edge) => edge.target === targetNode.id && edge.source !== sourceNode.id);
    if (partnerEdge) {
      // find the partner in the source nodes relationship_ids and check the marital status
      const relationships = sourceNode.data.profile.relationship_ids;
      const gender = sourceNode.data.gender;
      for (let i=0; i<relationships.length; i++) {
        // hack turn id's into strings for react-flow
        const spouse_id = (gender === 'male') ? relationships[i].mother_id+"" : relationships[i].father_id+"";
        if (spouse_id === partnerEdge.source) {
          if (relationships[i].marital_status === 'separated' && sourceNode.data.profile.is_blood_related_to_proband) {
            draw_separated_relationship = true;
          } else if (relationships[i].marital_status === 'divorced' && sourceNode.data.profile.is_blood_related_to_proband) {
            draw_divorced_relationship = true;
          }

          //make it so that being consanguineous only depends on is_parent_blood_related in relationship data and not depend on whether they are blood related to proband
          if (relationships[i].is_parent_blood_related) {
            draw_consanguineous_relationship = true;
          }
          break;
        }
      }
    }
  }

  let { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  let srcPerson = false;
  if (sourceNode.nodeType === "Person")
  {
	  srcPerson = true;
  }
  else
  {
	//   if (sourceNode.nodeType === "top")
	//   {
	// 	let spouseList = props.data.datastore.getSpouse(sourceNode.id);
	// 	if (spouseList.length > 0)
	// 	{
	// 		let ysum = 0;
	// 		for (let i = 0; i < spouseList.length; i++)
	// 		{
	// 			let spouse = spouseList[i];
	// 			let loc = props.data.datastore.getNodeLocation(spouse);
	// 			// console.log(spouse);
	// 			// console.log(loc);
	// 			ysum += loc["y"]
	// 			let x = document.getElementById("" + spouse);
	// 			// console.log(x);
	// 		}
	// 		// sy = ysum/2;
	// 		sourceNode.__rf.y = sy
	// 	}
	//   }
	//   console.log(sourceNode.__rf.y = sy);

  }

  let selected_node = Object.values(props.data.datastore.nodes).find(node => node.selected);

  let box = null;

  if (selected_node) {
    box = [selected_node.x, selected_node.y, 150, 150];
  }

  let sourceNodePos = [sx, sy];
  let targetNodePos = [tx, ty];

  let lineHeight = 0;
	let theID = id + "_zzz";
  let pathLine = null;
  // endpoint is used to determine the endpoint of the edge line so we can use it if needed
  let endpoint = { x: null, y: null };

  props.data.datastore.updateEdge({ "src": sourceNode.id, "target": targetNode.id, "type": "Spouse", "edgelist": [theID] });

  // add a second consanguineous line with an ID if its a consanguineous spouse relationship
  let consanguineous_line_id = null;
  if (draw_consanguineous_relationship) {
    consanguineous_line_id = theID + "_consanguineous";
    props.data.datastore.updateEdge({ "src": sourceNode.id, "target": targetNode.id, "type": "Spouse", "edgelist": [consanguineous_line_id] });
  }


  if(box && (props.data.datastore.hit(sourceNodePos, box) || props.data.datastore.hit(targetNodePos, box))){

    let inter = props.data.datastore.getIntersection(sourceNode.id, targetNode.id);

    if (inter.length === 0) // There are no nodes between, so make the line straight.
    {

      if (Math.abs(sx - tx) > 4) {
        if (draw_consanguineous_relationship) {
          pathLine = [
            (<line key={createUUID()} id={theID} x1={sx} y1={sy} x2={tx} y2={ty} style={style}></line>),
            (<line key={createUUID()} id={consanguineous_line_id} x1={sx} y1={sy - 5} x2={tx} y2={ty - 5} style={style}></line>)
          ];
        } else {
          pathLine = (<line id={theID} x1={sx} y1={sy} x2={tx} y2={ty} style={style}></line>);
        }
        endpoint = { x: tx, y: ty };
      }
      else {
        let adopted_in = "none";
        let intersection = props.data.datastore.getHorizonalLines(tx, sy, tx, ty);
        let items = [];
        let key = createUUID();
        if (intersection.length === 0) {
          theID = id + "_a";
          items.push(<line key={key} id={theID} x1={tx} y1={sy} x2={tx} y2={ty} style={style} strokeDasharray={adopted_in}></line>);
          endpoint = { x: tx, y: ty };
        }
        else {
          let hooksAndLines = props.data.datastore.splitVerticalLine(tx, sy, ty, intersection);
          let lines = hooksAndLines["lines"];
          for (let i = 0; i < lines.length; i++) {
            let line = createLine(lines[i], id, i, adopted_in, style);
            items.push(line);
          }
          let hooks = hooksAndLines["hooks"];
          for (let i = 0; i < hooks.length; i++) {
            let pathLine = createHook(hooks[i], id, i, markerEnd, style);
            items.push(pathLine);
          }

          const last_line = lines[lines.length - 1];
          endpoint = { x: last_line["x2"], y: last_line["y2"] };
        }
        pathLine = items;
      }
    }
    else {
      if (srcPerson) {
        lineHeight = Math.abs(sx - tx) / 4;
        lineHeight = Math.min(lineHeight, 80);
        lineHeight = Math.max(lineHeight, 40);

        if (draw_consanguineous_relationship) {
          const d1 = `M ${sx} ${sy} C ${sx} ${sy - lineHeight}, ${tx} ${ty - lineHeight / 2}, ${tx} ${ty}`;
          const p1 = (<path key={createUUID()} id={theID} className="react-flow__edge-path" d={d1} style={style} />);

          const d2 = `M ${sx} ${sy - 5} C ${sx} ${sy - lineHeight - 5}, ${tx} ${ty - (lineHeight / 2) - 5}, ${tx} ${ty - 5}`;
          const p2 = (<path key={createUUID()} id={consanguineous_line_id} className="react-flow__edge-path" d={d2} style={style} />);
          pathLine = [
            p1,
            p2
          ];
        } else {
          const d = `M ${sx} ${sy + 20} C ${sx} ${sy - lineHeight + 70}, ${tx} ${ty - lineHeight / 2}, ${tx} ${ty}`;
          if (props.data && 'isDirectStraightLine' in props.data) {
            // pathLine = <line id={theID} x1={sx} y1={sy+lineHeight/2} x2={tx} y2={ty} style={style} />
            pathLine = (<line id={theID} x1={sx} y1={sy} x2={tx} y2={ty} style={style}></line>);
          }
          else {
            pathLine = (<path id={theID} className="react-flow__edge-path" d={d} style={style} />);
          }
          endpoint = { x: tx, y: ty };
        }
      }
      else {
        pathLine = (<line id={theID} x1={sx} y1={sy} x2={tx} y2={ty} style={style}></line>);
      }

    }
  }
  else {
    if (srcPerson) {
      lineHeight = Math.abs(sx - tx) / 4;
      lineHeight = Math.min(lineHeight, 80);
      lineHeight = Math.max(lineHeight, 40);

      if (draw_consanguineous_relationship) {
        const d1 = `M ${sx} ${sy} C ${sx} ${sy - lineHeight}, ${tx} ${ty - lineHeight / 2}, ${tx} ${ty}`;
        const p1 = (<path key={createUUID()} id={theID} className="react-flow__edge-path" d={d1} style={style} />);

        const d2 = `M ${sx} ${sy - 5} C ${sx} ${sy - lineHeight - 5}, ${tx} ${ty - (lineHeight / 2) - 5}, ${tx} ${ty - 5}`;
        const p2 = (<path key={createUUID()} id={consanguineous_line_id} className="react-flow__edge-path" d={d2} style={style} />);
        pathLine = [
          p1,
          p2
        ];
      } else {
        const d = `M ${sx} ${sy + 20} C ${sx} ${sy - lineHeight + 70}, ${tx} ${ty - lineHeight / 2}, ${tx} ${ty}`;
        if (props.data && 'isDirectStraightLine' in props.data) {
          // pathLine = <line id={theID} x1={sx} y1={sy+lineHeight/2} x2={tx} y2={ty} style={style} />
          pathLine = (<line id={theID} x1={sx} y1={sy} x2={tx} y2={ty} style={style}></line>);
        }
        else {
          pathLine = (<path id={theID} className="react-flow__edge-path" d={d} style={style} />);
        }
        endpoint = { x: tx, y: ty };
      }
    }
    else {
      pathLine = (<line id={theID} x1={sx} y1={sy} x2={tx} y2={ty} style={style}></line>);
    }
  }


  let relationship_status_line = null;
  if (draw_separated_relationship) {
    relationship_status_line = (<line key={createUUID()} x1={endpoint.x-6} y1={endpoint.y+20} x2={endpoint.x+6} y2={endpoint.y-20} style={style}></line>);
  } else if (draw_divorced_relationship) {
    relationship_status_line = [
      (<line key={createUUID()} x1={endpoint.x-6} y1={endpoint.y+20} x2={endpoint.x+6} y2={endpoint.y-20} style={style}></line>),
      (<line key={createUUID()} x1={endpoint.x-14} y1={endpoint.y+20} x2={endpoint.x-2} y2={endpoint.y-20} style={style}></line>)
    ];
  }

  return (
    <g className="react-flow__connection">
    	{pathLine}
      {relationship_status_line}
		  <div stlye="font='italic 40px serif' stroke='blue' fill='red'"> {id}</div>
    </g>
  );
};

export default FloatingStraightEdge;
